import React from "react";
import {Box,} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuantitySize} from "../../../models/product";
import SizeChip from "../../../shared-components/sizeChip";

interface Props {
  readonly options: ReadonlyArray<QuantitySize>;
  readonly value?: string;
  readonly onClick: (selectedSize: string) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  parent: {
    '& > *:not(:first-child)': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& > *:first-child': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
}));

const SizeChips: React.FC<Props> = ({options, value, onClick}) => {

  const classes = useStyles();

  return (
    <Box className={classes.parent}>
      {options.map((quantitySize: QuantitySize) => (
        <SizeChip
          key={quantitySize.size}
          label={quantitySize.size}
          onClick={() => {
            onClick(quantitySize.size);
          }}
          disabled={quantitySize.quantity < 1}
          selected={value === quantitySize.size}
        />
      ))}
    </Box>
  );
}

export default SizeChips;
