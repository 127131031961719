import React, {useContext} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TranslationService} from "../../../services/translationService";
import {translationServiceContext} from "../../../services/provider";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {Button} from 'gatsby-theme-material-ui';

interface Props {
  readonly phoneNumber: string;
  readonly messageGenerator: (selectedSize: string) => string;
  readonly metadata: {
    readonly selectedSize?: string;
  };
  readonly onClickAndValid: (selectedSize: string) => void;
  readonly onClickAndNotValid: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  whatsAppButton: {
    backgroundColor: '#128C7E',
    color: theme.palette.getContrastText('#128C7E'),
    '&:hover': {
      backgroundColor: '#0c6258',
    },
  }
}));

const WhatsAppButton: React.FC<Props> = ({ phoneNumber, messageGenerator, onClickAndValid, onClickAndNotValid, metadata }) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={classes.whatsAppButton}
      onClick={(event: any) => {
        if (metadata.selectedSize) {
          onClickAndValid(metadata.selectedSize);
        } else {
          event.preventDefault();
          onClickAndNotValid();
        }
      }}
      href={metadata.selectedSize ? `https://wa.me/${phoneNumber}?text=${messageGenerator(metadata.selectedSize)}` : undefined}
      variant="contained"
      startIcon={<WhatsAppIcon/>}
      fullWidth
    >
      {translationService.translate('PRODUCT_DETAILS_BUY_ON_WHATSAPP')}
    </Button>
  );
}

export default WhatsAppButton;
