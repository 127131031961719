import { IGatsbyImageData } from 'gatsby-plugin-image';
import { CategorySummary } from './category';

export interface Product {
  readonly reference: string;
  readonly title: string;
  readonly colorId: string | null;
  readonly featured: boolean;
  readonly eligibleForSaleInStore: boolean;
  readonly eligibleForDelivery: boolean;
  readonly new: boolean;
  readonly sizeType: SizeType;
  readonly sellingPrice: number;
  readonly totalQuantity: number;
  readonly discountedPrice: number;
  readonly description: string | null;
  readonly quantityPerSize: ReadonlyArray<QuantitySize>;
  readonly slug: string;
  readonly category: CategorySummary;
  readonly specificCategory: CategorySummary;
  readonly images: ReadonlyArray<ImageModel & ImgixModel>;
  readonly featuredImage: ImageModel & ImgixModel;
  readonly variants: ReadonlyArray<Variant>;
  readonly googleCategoryId: string;
}

export interface ImageModel {
  readonly url: string;
  readonly height: number;
  readonly width: number;
  readonly model?: string;
  readonly productSize?: string;
}

export interface QuantityPerSizeSummary {
  readonly size: string;
  readonly quantity: number;
}

export interface ProductSummary {
  readonly slug: string;
  readonly reference: string;
  readonly title: string;
  readonly category: CategorySummary;
  readonly sellingPrice: number;
  readonly discountedPrice: number;
  readonly totalQuantity: number;
  readonly new: boolean;
  readonly colorId?: string;
  readonly colorIds: ReadonlyArray<string>;
  readonly featuredImage: ImageModel & ImgixModel;
  readonly quantityPerSize: ReadonlyArray<QuantityPerSizeSummary>;
}

export interface ImgixModel {
  readonly imgixImage: {
    readonly gatsbyImageData: IGatsbyImageData;
  };
}

export enum SizeType {
  PIECES = 'PIECES',
  EU = 'EU',
  US = 'US',
  STANDART = 'STANDART',
  F1T7 = '1-7',
}

export interface Variant {
  readonly title: string;
  readonly colorId: string;
  readonly sellingPrice: number;
  readonly totalQuantity: number;
  readonly slug: string;
  readonly image: ImageModel & ImgixModel;
}

export interface QuantitySize {
  readonly quantity: number;
  readonly size: string;
  readonly storeId: number;
}
