import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box, Grid, IconButton, useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useScreen } from '@mui-treasury/layout';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import sizeChart from '../assets/reference-data/size-chart.json';
import { TranslationService } from '../services/translationService';
import { translationServiceContext } from '../services/provider';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

interface Props {
  readonly open: boolean;
  readonly handleClose: () => void;
}

const SizeChartDialog: React.FC<Props> = ({ open, handleClose }) => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme = useTheme();

  const screen: Breakpoint = useScreen();
  const fullScreen = screen === 'xs' || screen === 'sm';

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>{translationService.translate('SIZE_CHART_CM')}</Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{`${translationService.translate('SIZE_CHART_SIZE')} (EU)`}</TableCell>
                <TableCell>{translationService.translate('SIZE_CHART_BUST')}</TableCell>
                <TableCell>{translationService.translate('SIZE_CHART_WAIST')}</TableCell>
                <TableCell>{translationService.translate('SIZE_CHART_HIPS')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(sizeChart.EU).map((size: string) => (
                <TableRow key={size}>
                  <TableCell component="th" scope="row">
                    <Box fontWeight="fontWeightMedium">{size}</Box>
                  </TableCell>
                  <TableCell>{sizeChart.EU[size].bust.cm}</TableCell>
                  <TableCell>{sizeChart.EU[size].waist.cm}</TableCell>
                  <TableCell>{sizeChart.EU[size].hips.cm}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={handleClose}>
          {translationService.translate('SIZE_CHART_CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SizeChartDialog;
