import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import Slider from 'react-slick';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { ImageModel, ImgixModel } from '../../../models/product';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pointer: {
    cursor: 'pointer',
  },
  // aspectRatio is equivalent to the css "aspect-ratio": 0.74
  aspectRatio: {
    position: 'relative',
    paddingTop: '135%',
    height: 0,
  },
}));

interface Props {
  readonly images: ReadonlyArray<ImageModel & ImgixModel>;
  readonly productTitle: string;
  readonly asNavFor?: Slider;
}

const ProductThumbsSlider: ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Slider>> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  // TODO: more generic ?
  const thumbnailImageSize = '100px';

  return (
    <Slider
      className="thumbnails"
      ref={ref}
      arrows={false}
      infinite
      slidesToShow={props.images.length}
      vertical
      focusOnSelect
      asNavFor={props.asNavFor}
    >
      {props.images.map((image: ImageModel & ImgixModel) => (
        <GatsbyImage
          image={image.imgixImage.gatsbyImageData}
          title={props.productTitle}
          alt={props.productTitle}
          sizes={thumbnailImageSize}
          key={image.url}
          imgStyle={{
            borderRadius: theme.shape.borderRadius,
          }}
          className={clsx(classes.pointer, classes.aspectRatio)}
        />
      ))}
    </Slider>
  );
});

export default ProductThumbsSlider;
