import { Box, CardMedia, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CallIcon from '@material-ui/icons/Call';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import Alert from '@material-ui/lab/Alert';
import { useRowGutterStyles } from '@mui-treasury/styles/gutter/row';
import clsx from 'clsx';
import { PageProps, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Button } from 'gatsby-theme-material-ui';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CategoryGraphQlResponse } from '../../../gatsby-node';
import { BreadcrumbEntry } from "../../models/breadcrumbEntry";
import { CategoryBase, CategorySummary } from '../../models/category';
import { ImageModel, ImgixModel, Product, QuantitySize, SizeType } from '../../models/product';
import { ColorsService } from '../../services/colorsService';
import { EventsService } from '../../services/eventsService';
import { MessagingService } from '../../services/messagingService';
import { colorServiceContext, eventsServiceContext, messagingServiceContext, translationServiceContext, } from '../../services/provider';
import { TranslationService } from '../../services/translationService';
import BadgeStatus from '../../shared-components/badgeStatus';
import ExternalLink from "../../shared-components/externalLink";
import Markdown from '../../shared-components/markdown';
import PreBreadcrumbs from '../../shared-components/preBreadcrumbs';
import SizeChartDialog from '../../shared-components/sizeChartDialog';
import { StateAction } from '../../state/globalState';
import {
  calculateCurrentProductPrice,
  getAvailableStockPerStore,
  getCategoryParentsList,
  isProductAvailableForShipping,
  isProductAvailableInStore,
  isSelectedSizeAvailableForStore,
} from '../../utils/general';
import { groupBy, requireNonNull } from '../../utils/operators';
import Perks from "./components/perks";
import ProductPriceDetails from "./components/productPriceDetails";
import ProductSlider from './components/productSlider';
import ProductSliderDialog from './components/productSliderDialog';
import ProductThumbsSlider from './components/productThumbsSlider';
import ProductTitle from "./components/productTitle";
import ProductVariantsThumbs from './components/productVariantsThumbs';
import SizeChips from './components/sizeChips';
import WhatsAppButton from "./components/whatsAppButton";
import { ProductDetailsContext } from "./productDetailsContext";
import modelsData from './../../assets/reference-data/models.json';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sizeChipsContainer: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    marginRight: `-${theme.spacing(2)}px`,
  },
  baseLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  generalConditionsOfSaleLink: {
    color: theme.palette.text.secondary,
  },
  mapLink: {
    color: '#017698',
  },
  pointer: {
    cursor: 'pointer',
  },
  breadcrumbPaper: {
    marginBottom: theme.spacing(2),
  },
  breadcrumbPaperSm: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  squareChip: {
    borderRadius: 0,
  },
  outOfStock: {
    backgroundColor: theme.palette.grey.A200,
    color: 'white',
  },
  carouselMarginSm: {
    margin: `-${theme.spacing(2)}px -${theme.spacing(2)}px 0px -${theme.spacing(2)}px`,
  },
  underline: {
    textDecoration: 'underline',
  },
  containerPaddingTop: {
    paddingTop: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(3),
  },
  inlineDisplay: {
    display: 'inline-block',
  },
  fullWidth: {
    width: '100%',
  }
}));

interface ProductSizeMessage {
  readonly message: string;
  readonly severity: 'info' | 'warning' | 'error';
}

export interface StoreDetails {
  readonly id: number;
  readonly googleMapsLink: string;
  readonly phoneNumber: string | undefined;
  readonly isMain: boolean;
  readonly descriptor: string;
  readonly address: {
    readonly line1: string;
    readonly line2: string | undefined;
    readonly city: string;
    readonly postalCode: string;
    readonly country: string;
  };
}

interface ItemDetailsProps {
  readonly product: Product;
  readonly categories: {
    readonly edges: {
      readonly node: {
        readonly frontmatter: CategoryGraphQlResponse;
      };
    }[];
  };
  readonly settings: {
    readonly currency: string;
    readonly currencySymbol: string;
  };
  readonly contact: {
    readonly frontmatter: {
      readonly whatsappNumber: string;
      readonly phoneNumber: string;
      readonly stores: StoreDetails[];
    };
  };
  readonly generalConditionsOfSale: {
    readonly edges: {
      readonly node: {
        readonly frontmatter: {
          readonly title: string;
          readonly slug: string;
        };
      };
    }[];
  };
  readonly delivery: {
    readonly frontmatter: {
      readonly casablancaDeliveryFee: number;
      readonly otherCitiesDeliveryFee: number;
    };
  };
  readonly general: {
    readonly frontmatter: {
      readonly title: string;
    };
  };
}

interface ProductState {
  // Product size (message) state
  readonly selectedSize: string | undefined;
  // Selected product size message state
  readonly sizeMessage: ProductSizeMessage | undefined;
}

const productActions = {
  SET_SIZE_MESSAGE: 'SET_SIZE_MESSAGE',
  SET_SELECTED_SIZE: 'SET_SELECTED_SIZE',
};

function productReducer(state: ProductState, action: StateAction): ProductState {
  switch (action.type) {
    case productActions.SET_SELECTED_SIZE:
      return {
        ...state,
        selectedSize: action.payload.value,
        // Reset size message on size selection change
        sizeMessage: undefined,
      };
    default:
      throw new Error();
  }
}

/**
 * Logic to define initial selected size for a given product based on query size param
 * @param querySelectedSize value coming from query param
 * @param product current product
 */
const defineInitialSize = (product: Product, querySelectedSize?: string | null): string | undefined => {
  if (product.totalQuantity < 1) {
    return undefined;
  }
  if (querySelectedSize) {
    const matchedSize: QuantitySize | undefined = product.quantityPerSize.find(
      (value: QuantitySize) => value.size === querySelectedSize && value.quantity > 0,
    );
    if (matchedSize) {
      return matchedSize?.size;
    }
    return product.quantityPerSize.find((value: QuantitySize) => value.quantity > 0)?.size;
  }
  return product.quantityPerSize.find((value: QuantitySize) => value.quantity > 0)?.size;
};

const ItemDetails: React.FC<PageProps<ItemDetailsProps, ProductDetailsContext>> = (props) => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);
  const messagingService: MessagingService = useContext<MessagingService>(messagingServiceContext);
  const colorService: ColorsService = useContext<ColorsService>(colorServiceContext);
  const eventsService: EventsService = useContext<EventsService>(eventsServiceContext);

  const {
    contact: {
      frontmatter: {
        whatsappNumber,
        phoneNumber,
        stores
      }
    },
    product,
    general: {
      frontmatter: {
        title
      }
    },
    delivery: {
      frontmatter: {
        casablancaDeliveryFee,
        otherCitiesDeliveryFee
      }
    },
    settings: {
      currencySymbol,
      currency
    },
    categories
  } = props.data;

  const category: CategoryGraphQlResponse = requireNonNull(categories.edges
    .find(({ node: { frontmatter } }) => frontmatter.ref === product.specificCategory.ref))
    .node.frontmatter;

  const categoriesMap: Map<number, CategoryGraphQlResponse> = new Map(categories.edges
    .map(({ node: { frontmatter } }) => [frontmatter.ref, frontmatter]));

  const mappedCategory: CategorySummary = {
    ref: category.ref,
    slug: category.slug,
    title: category.title,
    seoTitle: category.seoTitle,
    parents: getCategoryParentsList(category, categoriesMap)
      .map(cat => ({
        ref: cat.ref,
        title: cat.title,
        slug: cat.slug,
        parentRef: cat.parentRef,
      } as CategoryBase))
      .reverse(),
    children: []
  };

  const generalConditionsOfSale: { title: string; slug: string } | undefined = props.data.generalConditionsOfSale.edges[0]?.node.frontmatter;

  const productMetaData = {
    title: `${product.title} | ${title}`,
    relativeUrl: `${product.slug}`,
  };

  // Size chart dialog state
  const [openSizeChartDialog, setOpenSizeChartDialog] = React.useState<boolean>(false);

  const [openProductDialogImages, setOpenProductDialogImages] = React.useState(false);

  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);

  // Product slider state
  const [mainSliderRef, setMainSliderRef] = useState<Slider | undefined>(undefined);
  const [thumbnailsSliderRef, setThumbnailsSliderRef] = useState<Slider | undefined>(undefined);
  const [mainSlider, setMainSlider] = useState<Slider | undefined>(undefined);
  const [thumbnailsSlider, setThumbnailsSlider] = useState<Slider | undefined>(undefined);

  // Fullscreen product slider state
  let fullscreenMainSliderRef: Slider | undefined;
  const [fullscreenMainSlider, setFullscreenMainSlider] = useState<Slider | undefined>(undefined);

  React.useEffect(() => {
    setMainSliderRef(mainSlider);
    setThumbnailsSliderRef(thumbnailsSlider);

    setFullscreenMainSlider(fullscreenMainSliderRef);
    setOpenProductDialogImages(openProductDialogImages);
  }, [openProductDialogImages]);

  // Initial state
  // Select first size where the quantity is > 0
  const initialSizeSelection: string | undefined = defineInitialSize(product, null);

  const initialState: ProductState = {
    selectedSize: initialSizeSelection,
    sizeMessage:
      product.totalQuantity < 1
        ? {
          message: translationService.translate('PRODUCT_DETAILS_NO_SIZE_IS_AVAILABLE'),
          severity: 'warning',
        }
        : undefined,
  };

  const [state, dispatch] = React.useReducer(productReducer, initialState);

  // Generate stores map
  const storesMap: Map<number, StoreDetails> = new Map(
    stores.sort((a: StoreDetails, b: StoreDetails) => a.id.toString()
      .localeCompare(b.id.toString()))
      .map((store: StoreDetails) => [store.id, store] as [number, StoreDetails]),
  );

  // Get available product stock per store, ex. ["38", [1, 23]] means that for size 38, we have 23 items on the store with id 1.
  const availableStockPerStore: Map<string, Map<number, number>> = getAvailableStockPerStore(product);

  // Styles
  const theme: Theme = useTheme();
  const classes = useStyles();
  const gutterStyles = useRowGutterStyles();

  // mobile first
  const isDownSm: boolean = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const pageCanonicalURL: string = `${process.env.GATSBY_APP_URL}/${product.slug}/`;

  const outOfStock: boolean = product.totalQuantity < 1;

  const isProductAvailable: boolean = isProductAvailableInStore(product);

  const mainProductsSliderThumbnails = (
    <ProductThumbsSlider
      ref={(slider: Slider | null) => setThumbnailsSlider(slider)}
      asNavFor={mainSlider}
      images={product.images}
      productTitle={product.title}
    />
  );

  const mainProductsSlider = (
    <ProductSlider
      ref={(slider: Slider | null) => setMainSlider(slider)}
      asNavFor={thumbnailsSlider}
      onClick={((slideIndex: number) => {
        setCurrentImageIndex(slideIndex);
        setOpenProductDialogImages(true);
      })}
      images={product.images}
      productTitle={product.title}
    />
  );

  // Product color label (ex. `Color:`)
  const colorLabel = (
    <Box className={gutterStyles.parent}>
      <Box className={classes.inlineDisplay}>
        <Typography variant="subtitle1" component="div">
          {translationService.translate('PRODUCT_DETAILS_COLOR')}
          :
        </Typography>
      </Box>
      <Box className={classes.inlineDisplay}>
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightMedium">
            {product.colorId && colorService.getTranslatedColorKey(product.colorId)}
          </Box>
        </Typography>
      </Box>
    </Box>
  );

  // Product size label (ex. `Size (EU):`)
  const sizeLabel = (
    <Box className={gutterStyles.parent}>
      <Box className={classes.inlineDisplay}>
        <Typography variant="subtitle1" component="div">
          {`${translationService.translate('PRODUCT_DETAILS_SIZE')}${product.sizeType === SizeType.EU ? ' (EU)' : ''}: `}
        </Typography>
      </Box>
      <Box className={classes.inlineDisplay}>
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightMedium">{state.selectedSize}</Box>
        </Typography>
      </Box>
    </Box>
  );

  const totalSizesForAllStores: ReadonlyArray<QuantitySize> = Array.from(groupBy(product.quantityPerSize, (qps: QuantitySize) => qps.size)
    .values())
    .map((quantitySizes: QuantitySize[]) => quantitySizes.reduce(
      (previousValue: QuantitySize, currentValue: QuantitySize) => ({
        ...previousValue,
        quantity: previousValue.quantity + currentValue.quantity,
      }),
    ));

  const categoryWithParents: ReadonlyArray<CategoryBase> = mappedCategory.parents
    .concat(
      {
        slug: mappedCategory.slug,
        ref: mappedCategory.ref,
        title: mappedCategory.title,
        parentRef: mappedCategory.parentRef
      } as CategoryBase
    );

  const breadcrumbsItems: ReadonlyArray<BreadcrumbEntry> = [
    {
      link: '/',
      title: translationService.translate('BREADCRUMB_HOME')
    },
    ...categoryWithParents.map((cat, index) => {
      return {
        link: `/${categoryWithParents.slice(0, index + 1)
          .map(it => it.slug)
          .join("/")}/`,
        title: cat.title
      }
    }),
    {
      title: product.title
    }
  ];

  const modelProductSize: { height: string, productSize: string } | undefined = (() => {
    if (product.images.every(image => image.model && image.productSize)) {
      const firstImage: ImageModel = product.images[0];
      const modelDetails = modelsData[requireNonNull(firstImage.model)];
      return {
        height: modelDetails.height,
        productSize: requireNonNull(firstImage.productSize)
      }
    } else {
      return undefined;
    }
  })();

  const ldJson = {
    "@context": "https://schema.org/",
    "@graph": [
      {
        "@type": "Product",
        "name": product.title,
        "category": product.googleCategoryId,
        "description": product.description,
        "productID": product.reference,
        "color": product.colorId && colorService.getTranslatedColorKey(product.colorId),
        "image": product.images.map((image: ImageModel & ImgixModel) => getSrc(image.imgixImage.gatsbyImageData)),
        "offers": {
          "@type": "Offer",
          "url": pageCanonicalURL,
          "availability": `https://schema.org/${outOfStock ? 'OutOfStock' : 'InStock'}`,
          "price": calculateCurrentProductPrice(product.discountedPrice, product.sellingPrice),
          "priceCurrency": currency,
          "itemCondition": "https://schema.org/NewCondition"
        }
      },
      {
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbsItems.map((breadcrumbItem: BreadcrumbEntry, index: number) => ({
          "@type": "ListItem",
          "position": index + 1,
          "name": breadcrumbItem.title,
          "item": breadcrumbItem.link ? `${process.env.GATSBY_APP_URL}${breadcrumbItem.link}` : undefined
        }))
      }
    ]
  };

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{
        lang: 'fr',
      }}>
        <title>{productMetaData.title}</title>
        <link rel="canonical" href={pageCanonicalURL} />
        <meta name="description" content={product.description ?? undefined} />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
        {/* OpenGraph microdata */}
        <meta property="og:title" content={productMetaData.title} />
        <meta property="og:description" content={product.description ?? undefined} />
        <meta property="og:url" content={pageCanonicalURL} />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="product" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:image" content={getSrc(product.images[0]?.imgixImage?.gatsbyImageData)} />
        <meta property="og:image:width"
          content={product.images[0]?.imgixImage?.gatsbyImageData?.width?.toString() ?? undefined} />
        <meta property="og:image:height"
          content={product.images[0]?.imgixImage?.gatsbyImageData?.height?.toString() ?? undefined} />
      </Helmet>

      <ProductSliderDialog
        ref={(slider: Slider | null) => {
          if (slider) {
            fullscreenMainSliderRef = slider;
          }
        }}
        open={openProductDialogImages}
        handleClose={() => setOpenProductDialogImages(false)}
        images={product.images}
        currentImageIndex={currentImageIndex}
        title={product.title}
        onNext={() => {
          fullscreenMainSliderRef?.slickNext();
        }}
        onPrevious={() => {
          fullscreenMainSliderRef?.slickPrev();
        }}
      />

      <Container className={clsx(classes.container, isDownSm ? undefined : classes.containerPaddingTop)} disableGutters={isDownSm}>
        <SizeChartDialog
          open={openSizeChartDialog}
          handleClose={() => {
            setOpenSizeChartDialog(false);
          }}
        />
        <Box className={isDownSm ? classes.breadcrumbPaperSm : classes.breadcrumbPaper}>
          <PreBreadcrumbs isDownSm={isDownSm} items={breadcrumbsItems} />
        </Box>
        <div>
          {isDownSm && <CardMedia>{mainProductsSlider}</CardMedia>}
          {/* When we have multiple images, a number of dots appear below the slider */}
          <div
            style={
              isDownSm
                ? {
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                  paddingTop: theme.spacing(product.images.length > 1 ? 3 : 2),
                }
                : undefined
            }
          >
            <Grid container spacing={isDownSm ? 2 : 4}>
              {/* Product images carousel */}
              {!isDownSm && (
                <Grid item container spacing={1} md={7} lg={7} sm={12} xs={12}>
                  <Grid item md={2} lg={2}>
                    {mainProductsSliderThumbnails}
                  </Grid>
                  <Grid item md={10} lg={10} xs={12}>
                    {mainProductsSlider}
                  </Grid>
                </Grid>
              )}
              {/* Product  */}
              <Grid item container md={5} spacing={2} direction="column">
                <Grid item container spacing={1} direction="column">
                  {/* Product title */}
                  <Grid item>
                    <ProductTitle reference={product.reference} title={product.title} isNew={product.new} />
                  </Grid>
                  {/* Product price details */}
                  <Grid item container direction="column">
                    <Grid item>
                      <ProductPriceDetails
                        sellingPrice={product.sellingPrice}
                        discountedPrice={product.discountedPrice}
                        currencySymbol={currencySymbol}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary" component="p">
                        {`+ ${translationService
                          .translate('PRODUCT_DETAILS_SHIPPING_COSTS')
                          .format(casablancaDeliveryFee, otherCitiesDeliveryFee, currencySymbol)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Product colors and sizes */}
                  <Grid item container spacing={2}>
                    {/* Product colors */}
                    <Grid item container spacing={1} direction="column">
                      <Grid item>{colorLabel}</Grid>
                      <Grid item className={classes.fullWidth}>
                        <ProductVariantsThumbs product={product} />
                      </Grid>
                    </Grid>
                    {/* Product sizes */}
                    <Grid item container>
                      {product.sizeType !== SizeType.PIECES && (
                        <Grid item container direction="column" spacing={1}>
                          <Grid item>{sizeLabel}</Grid>
                          {state.sizeMessage && (
                            <Grid item>
                              <Alert severity={state.sizeMessage.severity}>{state.sizeMessage.message}</Alert>
                            </Grid>
                          )}
                          <Grid item>
                            <SizeChips
                              options={totalSizesForAllStores}
                              value={state.selectedSize}
                              onClick={(selectedSize): void => {
                                dispatch({
                                  type: productActions.SET_SELECTED_SIZE,
                                  payload: {
                                    value: selectedSize,
                                    data: {
                                      storesMap,
                                      availableStockPerStore,
                                    },
                                  },
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {product.sizeType === SizeType.PIECES && state.sizeMessage && (
                        <Grid item>
                          <Alert severity={state.sizeMessage.severity}>{state.sizeMessage.message}</Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  {/* Store and delivery availability */}
                  <Grid item container spacing={0}>
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item>
                        <LocalShippingOutlinedIcon />
                      </Grid>
                      <Grid item>
                        {isProductAvailable ? (
                          <DeliveryDetails />
                        ) : (
                          <Typography variant="body2">
                            {translationService.translate('PRODUCT_DETAILS_NOT_AVAILABLE_FOR_DELIVERY')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <BadgeStatus color={isProductAvailableForShipping(product) ? 'green' : 'red'} />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      {Array.from(storesMap.values())
                        .map((store: StoreDetails) => (
                          <Grid item container spacing={1} alignItems="center" key={store.id}>
                            <Grid item>
                              <StoreMallDirectoryOutlinedIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                {`${translationService.translate(
                                  isSelectedSizeAvailableForStore(availableStockPerStore, state.selectedSize, store.id)
                                    ? 'PRODUCT_DETAILS_AVAILABLE'
                                    : 'PRODUCT_DETAILS_NOT_AVAILABLE',
                                )} `}
                                <ExternalLink
                                  to={store.googleMapsLink}
                                  onClick={() => eventsService.handleGetDirections()}
                                  className={clsx(classes.baseLink, classes.mapLink)}
                                >
                                  {store.descriptor}
                                </ExternalLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <BadgeStatus
                                color={isSelectedSizeAvailableForStore(availableStockPerStore, state.selectedSize, store.id)
                                  ? 'green' : 'red'}
                              />
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  {/* Whatsapp and call buttons */}
                  {!outOfStock && (
                    <Grid item container direction="column" spacing={1}>
                      <Grid item>
                        <WhatsAppButton
                          phoneNumber={whatsappNumber}
                          metadata={{
                            selectedSize: state.selectedSize
                          }}
                          messageGenerator={(selectedSize) => messagingService.generateProductMessage(product, selectedSize, pageCanonicalURL)}
                          onClickAndNotValid={() => {
                            dispatch({
                              type: productActions.SET_SIZE_MESSAGE,
                              payload: {
                                message: translationService.translate('PRODUCT_DETAILS_CHOOSE_YOUR_SIZE'),
                                severity: 'error',
                              },
                            });
                          }}
                          onClickAndValid={(selectedSize) => {
                            eventsService.handleWhatsappCheckout(product.reference, product.title, selectedSize);
                          }}
                        />
                      </Grid>
                      {phoneNumber && (
                        <Grid item>
                          <Button
                            disableElevation
                            variant="outlined"
                            onClick={(event: any) => {
                              if (state.selectedSize === undefined) {
                                event.preventDefault();
                                dispatch({
                                  type: productActions.SET_SIZE_MESSAGE,
                                  payload: {
                                    message: translationService.translate('PRODUCT_DETAILS_CHOOSE_YOUR_SIZE'),
                                    severity: 'error',
                                  },
                                });
                              } else {
                                eventsService.handleCallCheckout();
                              }
                            }}
                            href={`tel:${phoneNumber}`}
                            startIcon={<CallIcon />}
                            fullWidth
                          >
                            {translationService.translate('PRODUCT_DETAILS_CALL_US')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {/* Description */}
                  {product.description && (
                    <Grid item>
                      <Markdown>{product.description}</Markdown>
                      {modelProductSize && (
                        <Markdown>{`**Taille du mannequin**: Le mannequin mesure ${modelProductSize.height} et porte une taille ${modelProductSize.productSize}.`}</Markdown>
                      )}
                    </Grid>
                  )}

                  {/* Perks */}
                  <Grid item className={classes.fullWidth}>
                    <Perks generalConditionsOfSale={generalConditionsOfSale} />
                  </Grid>
                  {/* Breadcrumbs */}
                  {isDownSm && (
                    <Grid item>
                      <Box>
                        <PreBreadcrumbs isDownSm={isDownSm} items={breadcrumbsItems} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

const DeliveryDetails = () => {
  return (
    <Typography variant="body2">
      Livraison <Box fontWeight='fontWeightMedium' display='inline'>rapide en 24h à 48h</Box>
    </Typography>
  )
}

export default ItemDetails;

export const productPageDetailsQuery = graphql`
    query GetProductPageData($productSlug: String) {
        general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
            frontmatter {
                title
            }
        }
        contact: markdownRemark(fileAbsolutePath: { regex: "/settings/contact.md$/" }) {
            frontmatter {
                whatsappNumber
                phoneNumber
                stores {
                    id
                    descriptor
                    googleMapsLink
                }
            }
        }
        settings {
            currency
            currencySymbol
        }
        generalConditionsOfSale: allMarkdownRemark(
            filter: {
                fileAbsolutePath: { regex: "//content/legal-agreements/(.)*/g" }
                frontmatter: { isActive: { eq: true }, type: { eq: "GENERAL_CONDITIONS_OF_SALE" } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                    }
                }
            }
        }
        delivery: markdownRemark(fileAbsolutePath: { regex: "/settings/delivery.md$/" }) {
            frontmatter {
                casablancaDeliveryFee
                otherCitiesDeliveryFee
            }
        }
        categories: allMarkdownRemark(filter: {
            fileAbsolutePath: { regex: "/.*(categories).*/" }
        }) {
            edges {
                node {
                    frontmatter {
                        ref
                        title
                        slug
                        parentRef
                    }
                }
            }
        }
        product: product(slug: { eq: $productSlug }) {
            slug
            reference
            title
            new
            totalQuantity
            specificCategory {
                ref
                slug
                parentRef
            }
            colorId
            sellingPrice
            discountedPrice
            totalQuantity
            googleCategoryId
            featuredImage {
                url
                imgixImage {
                    gatsbyImageData(
                        layout: FIXED
                        width: 40
                        placeholder: BLURRED
                        imgixParams: { auto: "format,compress", ar: "3:3", fit: "crop", fpZ: 4, crop: "focalpoint" }
                    )
                }
            }
            eligibleForDelivery
            eligibleForSaleInStore
            description
            sizeType
            variants {
                title
                slug
                colorId
                totalQuantity
                image {
                    url
                    imgixImage {
                        gatsbyImageData(
                            layout: FIXED
                            width: 40
                            placeholder: BLURRED
                            imgixParams: { auto: "format,compress", ar: "3:3", fit: "crop", fpZ: 4, crop: "focalpoint" }
                        )
                    }
                }
            }
            quantityPerSize {
                quantity
                size
                storeId
            }
            images {
                url
                model
                productSize
                imgixImage {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, imgixParams: { auto: "format, compress", ar: "3:4", fit: "crop" })
                }
            }
        }
    }
`;
