import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useState } from 'react';
import Slider from 'react-slick';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, Divider, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { ImageModel, ImgixModel } from '../../../models/product';
import '../../../utils/extensions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullScreenProductSlider from '../../../shared-components/fullScreenProductSlider';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface Props {
  readonly open: boolean;
  readonly images: ReadonlyArray<ImageModel & ImgixModel>;
  readonly currentImageIndex: number;
  readonly title: string;
  readonly asNavFor?: Slider;
  readonly handleClose: () => void;
  readonly onPrevious: () => void;
  readonly onNext: () => void;
}

const ProductSliderDialog: ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Slider>> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
    >
      <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
        <Grid item container justifyContent="flex-end">
          <IconButton onClick={props.handleClose}>
            <ClearIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item xs>
          <FullScreenProductSlider
            ref={ref}
            images={props.images.filter((image, index) => index === props.currentImageIndex)
              .concat(props.images.filter((image, index) => index !== props.currentImageIndex))}
            productTitle={props.title}
            beforeChange={(current, next) => {
              setCurrentSlideIndex(next);
            }}
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton
              disabled={props.images.length <= 1}
              onClick={props.onPrevious}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {`${currentSlideIndex + 1} / ${props.images.length}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={props.images.length <= 1}
              onClick={props.onNext}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
});

export default ProductSliderDialog;
