import React, {useContext} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {GatsbyImage} from 'gatsby-plugin-image';
import {colorServiceContext} from '../../../services/provider';
import {ColorsService} from '../../../services/colorsService';
import {ImageModel, ImgixModel, Product, Variant,} from '../../../models/product';
import {Link} from "gatsby-theme-material-ui";

const useStyles = makeStyles((theme: Theme) => createStyles({
  thumb: {
    width: 40,
    aspectRatio: '3/3',
    border: 'solid 2px',
  },
  div: {
    display: 'block',
    height: '100%',
  },
  selectedThumb: {
    borderColor: `${theme.palette.primary.main}`,
  },
  unselectedThumb: {
    borderColor: '#e5e5e5',
    cursor: 'pointer',
  },
  imageContainer: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: '100%',
  },
  outOfStock: {
    // todo
  },
  parent: {
    '& > *:not(:first-child)': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& > *:first-child': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
    // get rid of additional spacing caused by parent children
    marginBottom: `-${theme.spacing(1)}px`,
  },
}));

interface ProductVariant {
  readonly title: string;
  readonly image: ImageModel & ImgixModel;
  readonly colorId: string;
  readonly totalQuantity: number;
  readonly uri: string;
  readonly isSelected: boolean;
}

const ProductVariantsThumbs: React.FC<{ product: Product }> = ({product}) => {
  // Inject required services
  const colorService: ColorsService = useContext<ColorsService>(colorServiceContext);

  const classes = useStyles();

  const variants: ReadonlyArray<ProductVariant> = [
    {
      title: product.title,
      image: product.featuredImage,
      colorId: product.colorId,
      uri: `/${product.slug}/`,
      isSelected: true,
      totalQuantity: product.totalQuantity,
    } as ProductVariant,
  ]
    .concat(
      product.variants.map((variant: Variant) => ({
        title: variant.title,
        image: variant.image,
        colorId: variant.colorId,
        uri: `/${variant.slug}/`,
        isSelected: false,
        totalQuantity: variant.totalQuantity,
      })),
    )
    .sort((a, b) => {
      const comparison: number = b.totalQuantity - a.totalQuantity;
      if (comparison === 0) {
        return a.colorId.localeCompare(b.colorId);
      }
      return comparison;
    });

  const thumbClassNameByVariant: (ProductVariant) => string = (variant: ProductVariant) => clsx(
    classes.thumb,
    variant.isSelected ? classes.selectedThumb : classes.unselectedThumb,
    variant.totalQuantity > 0 ? undefined : classes.outOfStock
  );

  const thumbImage: React.FC<ProductVariant> = (variant: ProductVariant) => {
    const variantImageTitle: string = variant.colorId ? colorService.getTranslatedColorKey(variant.colorId) : variant.title;
    return (
      <GatsbyImage
        image={variant.image.imgixImage.gatsbyImageData}
        title={variantImageTitle}
        alt={variantImageTitle}
        objectFit="contain"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    )
  };

  return (
    <Box className={classes.parent}>
      {variants.map((variant: ProductVariant) => {
        return (
          <Box key={variant.colorId} display="inline-block">
            {variant.isSelected ? (
              <div className={thumbClassNameByVariant(variant)}>
                {thumbImage(variant)}
              </div>
            ) : (
              <Link className={clsx(classes.div, thumbClassNameByVariant(variant))} to={variant.isSelected ? undefined : variant.uri}>
                {thumbImage(variant)}
              </Link>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductVariantsThumbs;
