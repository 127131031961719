import React from 'react';
import Brightness1Icon from '@material-ui/icons/Brightness1';

interface Props {
  color: string;
}

const BadgeStatus: React.FC<Props> = ({ color }) => <Brightness1Icon style={{ color, fontSize: 9 }} />;

export default BadgeStatus;
