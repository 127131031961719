import React, {useContext} from "react";
import {List, ListItem, ListItemAvatar, ListItemText, Paper, useTheme,} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import {TranslationService} from "../../../services/translationService";
import {translationServiceContext} from "../../../services/provider";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";
import clsx from 'clsx';
import {Link} from 'gatsby-theme-material-ui';

interface Props {
  readonly generalConditionsOfSale?: {
    readonly slug: string;
    readonly title: string;
  };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  paperBackground: {
    backgroundColor: theme.palette.grey['50']
  },
  baseLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  generalConditionsOfSaleLink: {
    color: theme.palette.text.secondary,
  }
}));

const Perks: React.FC<Props> = ({generalConditionsOfSale}) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();

  return (
    <Paper className={classes.paperBackground} elevation={0}>
      <List dense>
        <ListItem>
          <ListItemAvatar>
            <LocalShippingOutlinedIcon color="primary"/>
          </ListItemAvatar>
          <ListItemText
            primary={translationService.translate('PRODUCT_DETAILS_DELIVERY')}
            secondary={translationService.translate('PRODUCT_DETAILS_DELIVERY_DETAILS')}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <PaymentOutlinedIcon color="primary"/>
          </ListItemAvatar>
          <ListItemText
            primary={translationService.translate('PRODUCT_DETAILS_PAYMENT_ON_DELIVERY')}
            secondary={translationService.translate('PRODUCT_DETAILS_PAYMENT_ON_DELIVERY_DETAILS')}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <BeenhereOutlinedIcon color="primary"/>
          </ListItemAvatar>
          <ListItemText
            primary={translationService.translate('PRODUCT_DETAILS_RETURNING_YOUR_PRODUCTS')}
            secondary={(
              <>
                {translationService.translate('PRODUCT_DETAILS_RETURNING_YOUR_PRODUCTS_DETAILS')}
                {generalConditionsOfSale && (
                  <>
                    {/* TODO: translation */}
                    {', voir '}
                    <Link
                      to={`/${generalConditionsOfSale.slug}/`}
                      className={clsx(classes.baseLink, classes.generalConditionsOfSaleLink)}
                    >
                      {generalConditionsOfSale.title.toLocaleLowerCase()}
                    </Link>
                  </>
                )}
              </>
            )}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default Perks;
