import React, {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';
import Slider from 'react-slick';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {GatsbyImage, getSrc} from 'gatsby-plugin-image';
import {ImageModel, ImgixModel} from '../../../models/product';
import '../../../utils/extensions';

const useStyles = makeStyles((theme: Theme) => createStyles({
  // aspectRatio is equivalent to the css "aspect-ratio": 0.74
  slide: {
    aspectRatio: '3/4',
    cursor: 'zoom-in',
  },
  fullWidthHeight: {
    width: '100%',
    height: '100%',
  },
  borderRadius: {
    borderRadius: theme.shape.borderRadius,
  },
}));

interface Props {
  readonly images: ReadonlyArray<ImageModel & ImgixModel>;
  readonly productTitle: string;
  readonly onClick: (slideIndex: number) => void;
  readonly asNavFor?: Slider;
}

const ProductSlider: ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Slider>> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const imageSizesAttribute: string = [
    // TODO: what about having a size bigger than the width of the image ?
    // because the parent container has a predefined maximum width `theme.breakpoints.values.lg`, we used here pixels instead of viewport
    // 2.4 = 2 (first side slider, second one info) + 0.4 (spacing)
    `(min-width: ${theme.breakpoints.values.lg}px) ${theme.breakpoints.values.lg / 2.4}px`,
    `(min-width: ${theme.breakpoints.values.md}px) ${100 / 2.4}vw`,
    '100vw',
  ].join(', ');

  return (
    <Slider
      className="main"
      ref={ref}
      asNavFor={props.asNavFor}
      style={{
        left: 0,
        right: 0,
      }}
      fade={!isDownSm}
      arrows={false}
      slidesToShow={1}
      slidesToScroll={1}
      dots
      lazyLoad="progressive"
    >
      {props.images.map((image: ImageModel & ImgixModel, index: number) => (
        <div
          key={image.url}
          onClick={() => {
            props.onClick(index);
          }}
          className={classes.slide}
        >
          <GatsbyImage
            image={image.imgixImage.gatsbyImageData}
            title={props.productTitle}
            alt={props.productTitle}
            sizes={imageSizesAttribute}
            className={classes.fullWidthHeight}
            imgClassName={isDownSm ? undefined : classes.borderRadius}
          />
        </div>
      ))}
    </Slider>
  );
});

export default ProductSlider;
