import React, {useContext} from "react";
import {Box, Chip, Grid, Typography, useTheme,} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TranslationService} from "../../../services/translationService";
import {translationServiceContext} from "../../../services/provider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import WhatshotIcon from '@material-ui/icons/Whatshot';

interface Props {
  readonly reference: string;
  readonly title: string;
  readonly isNew: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  new: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ProductTitle: React.FC<Props> = ({reference, title, isNew}) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme: Theme = useTheme();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Grid container direction="column">
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant={isDownSm ? 'h6' : 'h5'} component="h1">
            <Box fontWeight="fontWeightMedium">
              {title}
            </Box>
          </Typography>
        </Grid>
        {isNew && (
          <Grid item>
            <Chip
              icon={<WhatshotIcon/>}
              label={translationService.translate('PRODUCT_DETAILS_NEW')}
              size="small"
              className={classes.new}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {`${translationService.translate('PRODUCT_DETAILS_REFERENCE')} ${reference}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ProductTitle;
