import React, { PropsWithChildren } from 'react';
import { Chip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
  chip: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: '2px',
    position: 'relative',
  },
  unselectedChip: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  disabledChip: {
    '&:after': {
      position: 'absolute',
      left: '0',
      top: '50%',
      height: '2px',
      background: theme.palette.text.disabled,
      transform: 'rotate(160deg)',
      content: '\'\'',
      width: '100%',
      display: 'block',
    },
    backgroundColor: theme.palette.grey.A100,
  },
  selectedChip: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

interface Props {
  readonly label: string;
  readonly selected: boolean;
  readonly disabled: boolean;
  readonly onClick: () => void;
}

const SizeChip: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const classes = useStyles();

  return (
    <Chip
      label={props.label}
      onClick={props.onClick}
      disabled={props.disabled}
      variant="outlined"
      className={clsx(classes.chip, props.selected ? classes.selectedChip : classes.unselectedChip, props.disabled ? classes.disabledChip : undefined)}
    />
  );
};

export default SizeChip;
