import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import Slider from 'react-slick';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ImageModel, ImgixModel } from '../models/product';
import '../utils/extensions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const useStyles = makeStyles((theme: Theme) => createStyles({
  height: {
    height: 'inherit',
    maxHeight: 'calc(100vh - 130px)',
  },
}));

interface Props {
  readonly images: ReadonlyArray<ImageModel & ImgixModel>;
  readonly productTitle: string;
  readonly asNavFor?: Slider;
  readonly style?: object;
  readonly onInit?: () => void;
  readonly beforeChange?: (currentSlide: number, nextSlide: number) => void;
}

const FullScreenProductSlider: ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Slider>> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Slider
      {...props}
      ref={ref}
      asNavFor={props.asNavFor}
      slidesToShow={1}
      slidesToScroll={1}
      arrows={!isDownSm}
      swipe={false}
      speed={300}
      infinite
      lazyLoad
    >
      {props.images.map((image: ImageModel & ImgixModel) => (
        <TransformWrapper
          key={image.url}
          options={{
            maxScale: 4,
          }}
        >
          <TransformComponent>
            <GatsbyImage
              alt={props.productTitle}
              objectFit="contain"
              className={classes.height}
              image={image.imgixImage.gatsbyImageData}
            />
          </TransformComponent>
        </TransformWrapper>
      ))}
    </Slider>
  );
});

export default FullScreenProductSlider;
