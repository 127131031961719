import React, {useContext} from "react";
import {Box, Chip, Grid, Typography,} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TranslationService} from "../../../services/translationService";
import {translationServiceContext} from "../../../services/provider";
import {calculateCurrentProductPrice} from "../../../utils/general";

interface Props {
  readonly sellingPrice: number;
  readonly discountedPrice?: number;
  readonly currencySymbol: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  discountChip: {
    color: theme.palette.background.paper,
  },
  lineThrough: {
    textDecoration: 'line-through'
  },
  fontWeightMedium: {
    fontWeight: 500
  }
}));

/**
 * Discount rate calculator (from 0 to 100, no decimals)
 * @param sellingPrice
 * @param discountedPrice
 */
const discountRateCalculator: (sellingPrice: number, discountedPrice: number) => string = (sellingPrice, discountedPrice) => {
  return discountedPrice > 0 ? (((sellingPrice - discountedPrice) * 100) / sellingPrice).roundAndMapToString(0) : '0';
};

const ProductPriceDetails: React.FC<Props> = ({ sellingPrice, discountedPrice, currencySymbol }) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const currentProductPrice: number = calculateCurrentProductPrice(sellingPrice, discountedPrice);

  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="center">
      {(discountedPrice && discountedPrice > 0) ? (
        <Grid item>
          <Box fontWeight="fontWeightMedium">
            <Typography variant="h5" color="textSecondary" component="div" className={classes.lineThrough}>
              {`${sellingPrice} ${currencySymbol}`}
            </Typography>
          </Box>
        </Grid>
      ) : undefined}
      <Grid item>
        <Typography variant="h5" color="primary" component="div" className={classes.fontWeightMedium}>
          {`${currentProductPrice} ${currencySymbol}`}
        </Typography>
      </Grid>
      {(discountedPrice && discountedPrice > 0) ? (
        <Grid item>
          <Chip
            label={`${discountRateCalculator(sellingPrice, discountedPrice)}% ${translationService.translate('PRODUCT_DETAILS_DISCOUNT_RATE')}`}
            color="primary"
            size="small"
            className={classes.discountChip}
          />
        </Grid>
      ) : undefined}
    </Grid>
  );
}

export default ProductPriceDetails;
